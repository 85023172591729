@import url(https://fonts.googleapis.com/css?family=Ubuntu);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  font-family: "Ubuntu", sans-serif;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  grid-gap: 20px;
  margin: 20px auto;
  width: 90%;
  transition: all 0.3s linear;
}

.product-card {
  position: relative;
  box-shadow: 0 2px 7px #dfdfdf;
  background: #fafafa;
  transition: all 0.2s linear;
}

.badge {
  position: absolute;
  left: 0;
  top: 20px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  background: red;
  color: #fff;
  padding: 3px 10px;
}

.product-tumb {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  padding: 50px;
  background: #fff;
}

.product-tumb img {
  max-width: 100%;
  max-height: 100%;
}

.product-details {
  padding: 30px;
}

.product-catagory {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccc;
  margin-bottom: 18px;
}

.product-details p {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 18px;
  color: #999;
}

.product-bottom-details {
  overflow: hidden;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.product-bottom-details div {
  float: left;
  width: 50%;
}

.product-price {
  font-size: 18px;
  color: #fbb72c;
  font-weight: 600;
}

.product-price small {
  font-size: 80%;
  font-weight: 400;
  text-decoration: line-through;
  display: inline-block;
  margin-right: 5px;
}

.product-links {
  text-align: right;
}

.btn-card {
  margin-right: 8px;
}

.btn-nav {
  padding: 0 20px !important;
}

.cart-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 20px;
  padding: 40px 80px;
}

.cart-item:nth-child(n + 1) {
  border-radius: 5px;
}

.cart-item img {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; 
   max-width: 40%;
  margin: 10px auto;
  /* max-height: 60%; */
}

.card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 0 0;
}

.card-body p {
  margin-bottom: 0;
}

.img-fluid {
  width: 70%;
  object-fit: contain;
}

.product-detail-title {
  color: #192a51 !important;
}

.product-detail-description {
  padding: 10px 40px 0 40px;
  color: #274060 !important;
}

.btn-add-cart {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.btn-add-cart i {
  margin-left: 10px;
}

.product-details-wrapper {
  padding: 50px 150px;
}
body {
    background-color: rgb(228, 229, 247);
}
.social-login img {
    width: 24px;
}
a {
    text-decoration: none;
}

.card {
    font-family: sans-serif;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
    margin-bottom: 3em;
    border-radius: 10px;
    background-color: #ffff;
    padding: 1.8rem;
    box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.1);
}

.title {
    text-align: center;
    font-weight: bold;
    margin: 0;
}
.subtitle {
    text-align: center;
    font-weight: bold;
}
.btn-text {
    margin: 0;
}

.social-login {
    display: flex;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
}

.google-btn {
    background: #fff;
    border: solid 2px rgb(245 239 239);
    border-radius: 8px;
    font-weight: bold;
    display: flex;
    padding: 10px 10px;
    flex: auto;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    justify-content: center;
    transition: all .2s linear;
    cursor: pointer;
}

.google-btn:hover {
    background:  rgb(69, 69, 185);
    color: #fff;
}

.fb-btn {
    background: #fff;
    border: solid 2px rgb(69, 69, 185);
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: all .2s linear;
    cursor: pointer;
}

.fb-btn:hover {
    background:  rgb(69, 69, 185);
    color: #fff;
}

.or {
    text-align: center;
    font-weight: bold;
    border-bottom: 2px solid rgb(245 239 239);
    line-height: 0.1em;
    margin: 25px 0;
}
.or span {
    background: #fff;
    padding: 0 10px;
}

.email-login {
    display: flex;
    flex-direction: column;
}
.email-login label {
    color: rgb(170 166 166);
}

input[type="text"],
input[type="password"] {
    padding: 15px 20px;
    margin-top: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
}

.cta-btn {
    background-color: rgb(69, 69, 185);
    color: white;
    padding: 12px 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 10px;
    border: none;
    transition: all .2s linear;
    cursor: pointer;
    border: 2px solid;
}

.cta-btn:hover {
    background-color: #FFF;
    color: rgb(69, 69, 185);
}

.forget-pass {
    text-align: center;
    display: block;
}

.alert-error {
    padding: 6px 8px;
    border-radius: 4px;
    margin: 10px auto;
    background-color: rgba(255, 0, 0, 0.534);
    color: #fff;
    text-align: center;
}

:disabled {
    pointer-events: none;
}

.user-info {
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: space-between;
}

.user-info p {
    margin-right: 20px;
    font-weight: 400;
}

.user-info .ant-avatar {
    margin-top: -14px;
}
