body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@import url("https://fonts.googleapis.com/css?family=Ubuntu");

body {
  font-family: "Ubuntu", sans-serif;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  grid-gap: 20px;
  margin: 20px auto;
  width: 90%;
  transition: all 0.3s linear;
}

.product-card {
  position: relative;
  box-shadow: 0 2px 7px #dfdfdf;
  background: #fafafa;
  transition: all 0.2s linear;
}

.badge {
  position: absolute;
  left: 0;
  top: 20px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  background: red;
  color: #fff;
  padding: 3px 10px;
}

.product-tumb {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  padding: 50px;
  background: #fff;
}

.product-tumb img {
  max-width: 100%;
  max-height: 100%;
}

.product-details {
  padding: 30px;
}

.product-catagory {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccc;
  margin-bottom: 18px;
}

.product-details p {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 18px;
  color: #999;
}

.product-bottom-details {
  overflow: hidden;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.product-bottom-details div {
  float: left;
  width: 50%;
}

.product-price {
  font-size: 18px;
  color: #fbb72c;
  font-weight: 600;
}

.product-price small {
  font-size: 80%;
  font-weight: 400;
  text-decoration: line-through;
  display: inline-block;
  margin-right: 5px;
}

.product-links {
  text-align: right;
}

.btn-card {
  margin-right: 8px;
}

.btn-nav {
  padding: 0 20px !important;
}

.cart-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 20px;
  padding: 40px 80px;
}

.cart-item:nth-child(n + 1) {
  border-radius: 5px;
}

.cart-item img {
  width: max-content; 
   max-width: 40%;
  margin: 10px auto;
  /* max-height: 60%; */
}

.card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 0 0;
}

.card-body p {
  margin-bottom: 0;
}

.img-fluid {
  width: 70%;
  object-fit: contain;
}

.product-detail-title {
  color: #192a51 !important;
}

.product-detail-description {
  padding: 10px 40px 0 40px;
  color: #274060 !important;
}

.btn-add-cart {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.btn-add-cart i {
  margin-left: 10px;
}

.product-details-wrapper {
  padding: 50px 150px;
}